<template>
    <div class="waitSign">
        <div  @click="siteInfoClick(siteInfo.stationNo)">
            <header-site-info  bg-color="#63B57E" :siteName="siteInfo.stationName"
                               :siteNo="siteInfo.areaNo" :siteDepart="siteInfo.deptName" :masterName="siteInfo.masterName"> </header-site-info>

        </div>
        <div class="content">
            <van-field label="站点状态" :value="siteInfo.stationStatus" readonly input-align="right"/>
            <van-field label="合作银行" v-if="siteInfo.cooperationBank != null" :value="siteInfo.cooperationBank" readonly input-align="right"/>
            <van-field label="站点管理责任人" label-width="120px" :value="siteInfo.marketerName" readonly input-align="right"/>
        </div>
        <div class="footer">
            <div class="agreement flex">
                <van-checkbox v-model="checked" shape="square" checked-color="#3F7C53" icon-size="0.3rem"> </van-checkbox>
                <div class="agreement_item">我已阅读
                    <span v-for="(item, index) in agreementListArr" :key="index" @click="protocolClick(item.agreementNo)">{{item.agreementName}}</span>
                </div>
            </div>
            <van-button type="info" color="#3F7C53" @click="signClick">签约</van-button>
        </div>
    </div>

</template>

<script>
    import HeaderSiteInfo from "../../components/siteInfo/headerSiteInfo";
    import common from '@/utils/common'
    import { getSiteByMasterNo,getReSignAgreement,getAgreementInfoByType,reSignContract} from '@/utils/api'
    import {Toast} from "vant";
    import {appAction} from "../../utils/util";
    import {AUTH_SUBMIT} from "../../utils/constantNum";
    export default {
        name: "notSign.vue",
        components:{
            HeaderSiteInfo
        },
        data(){
            return{
                checked: false,
                siteInfo: {},
                agreementListArr:[]

            }
        },
        created() {
            this.getsiteInfo();
        },
        methods:{
            siteInfoClick() {
                this.$router.push({name: 'siteInfo', query:{stationNo: this.siteInfo.stationNo}})
            },
            getsiteInfo() {
                getSiteByMasterNo({
                    masterNo: common.getItem('uniqueNo')
                }).then(res=>{
                    if(res.code ==200){
                        if(res.data && res.data.businessCode ==200){
                            this.siteInfo = res.data.data;
                            this.getAgreementList()
                        }
                    }
                })
            },
            getAgreementList(){
                getReSignAgreement({masterNo: common.getItem('uniqueNo')}).then(res=>{
                    if(res.code ==200){
                        if(res.data && res.data.businessCode ==200){
                            this.agreementListArr = res.data.data
                        }
                    }

                })
            },
            protocolClick(agreementNo) {
                getAgreementInfoByType({
                    token: common.getItem('wtToken'),
                    agreementNo,
                    uniqueNo: common.getItem('uniqueNo') ? common.getItem('uniqueNo') : common.getItem('masterNo')
                }).then(res=>{
                    if(res.code === 200){
                        if(res.data.businessCode && res.data.businessCode === 200){
                            this.$router.push({path:res.data.data.agreementPath,
                                query:{stationNo:this.siteInfo.stationNo,sign:'sign',
                                agreementParams:res.data.data.agreementParams}})
                        }
                    }
                })
            },
            signClick() {

                if(!this.checked){
                    Toast('请勾选协议')
                    return
                }
                if(!common.getItem('signAgent')){
                    Toast('请查看并签署协议')
                    return
                }
                reSignContract({
                    token: common.getItem('wtToken'),
                    stationNo: this.siteInfo.stationNo,
                    uniqueNo: common.getItem('uniqueNo'),
                    agreementList: this.agreementListArr,
                }).then(res=>{
                    if(res.code ==200){
                        if(res.data && res.data.businessCode ==200){
                            if (common.getItem('jumpFrom') != undefined) {
                                appAction(AUTH_SUBMIT)
                            }
                            // this.$router.push({name: 'submitted',query:{sign:'changeSign'}})
                            this.$router.push({name: 'submitted', query: {
                                    sign: 'changeSign'
                                }});
                            common.setItem('authStep', 'signed')
                            localStorage.removeItem('signAgent')
                        }
                    }
                })
            },

        }
    }
</script>

<style lang="less" scoped>
    .waitSign{
        height: 100%;
        font-size: 0.3rem;
        box-sizing: border-box;
        padding-top: 0.3rem;
    .flex{
        display: flex;
    }
    .title{
        align-items: center;
        justify-content: space-between;
        padding: 0.2rem 0.3rem;
        border-top: 0.1rem solid #555;
        background: #fff;
    .logo-img{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.3rem;
    }
    .title_left{
        align-items: center;
    }
    .title_mid{
        margin-left: 0.3rem;
    }
    }
    .content{
        margin-top: 0.3rem;
    }
    .footer{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 10;
    .van-button{
        width: 100%;
    }
    .agreement{
        padding: 0.2rem 0.3rem;
        align-items: flex-start;
        font-size: 0.24rem;
    span{
        color: #1989fa;
    }
    .agreement_item{
        margin-left: 0.1rem;
    span{
        color: #3F7C53;
    }
    }
    .van-checkbox{
        flex: 0 0 0.3rem;
    }
    }
    }
    .time-desc{
        margin: 0.2rem;
        font-size: 0.26rem;
    }
    }
</style>
